export const Logo = () => {
  const logoColor = {
    fill: "black",
  };

  const logoSize = {
    width: "28px",
    height: "auto",
  };

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 547.73 756.62"
      style={logoSize}
    >
      <path
        style={logoColor}
        d="M473.25,24.71V40.07a37,37,0,0,1,37,37V580.72L263.72,252.18a76.7,76.7,0,0,0-26.25,36.56c-1.11,3.17-2.1,6.37-3.05,9.59L532,689.71l.42-610.36a39.31,39.31,0,0,1,39.31-39.28V24.71Z"
        transform="translate(-24 -24.71)"
      />
      <path
        style={logoColor}
        d="M190,155.92,89.53,24.71H24V36.17A41.47,41.47,0,0,1,64.65,77.63V641a32.5,32.5,0,0,1-32.15,32.5l-6.9.07v15.89h97.78V673.54c-.09-.09-3.72-.26-3.72-.26A33.31,33.31,0,0,1,86.53,640l.24-532.39L161.42,204A490.75,490.75,0,0,1,190,155.92Z"
        transform="translate(-24 -24.71)"
      />
      <path
        style={logoColor}
        d="M571.72,765.72c-18.64-2.45-38-18-51.85-35.81C509.06,716,370,519,355.94,498.16l-10.75-16c-1.5,1.21-3,2.41-4.56,3.58-1,.75-2,1.49-3,2.19q-31.22,21.15-66.15,8.89-46.5-16.32-62.84-85.52t5.29-130.87q13-37.14,47.56-56.75t67-8.23q47.32,16.6,61.46,83.76a244,244,0,0,1,3.73,81.52l31.69,41.39a213.29,213.29,0,0,0,11.51-26.84q22.78-64.92,2.52-122.09t-72.81-75.6q-29.44-10.33-66.38-3.68t-71.88,41.95l-.65-1.68c16.46-40.18,95.8-188,195.65-192.46V25.54C272.61,39.4,185.7,198.84,165.58,256.16q-29.93,85.3-5.28,158.17t86.26,94.5c18.57,6.51,37.34,9.14,56.22,7.27,0,0,173.43,254,182.75,265.23h86.19Z"
        transform="translate(-24 -24.71)"
      />
      <path
        style={logoColor}
        d="M249.5,781.12V769.76l-4.61,0a32.41,32.41,0,0,1-32.07-32.53l.74-215.92q-36.18-20.19-58.73-58.62l-.52,276.46A30.66,30.66,0,0,1,124,769.7l-5.77.06v11.36Z"
        transform="translate(-24 -24.71)"
      />
    </svg>
  );
};
